import React, { useEffect, useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { HiOutlineDocumentText } from "react-icons/hi";
import { connect, useSelector } from "react-redux";
import { uploadFile } from "../../redux/actions/fileActions";
import { useDispatch } from "react-redux";

const FileUploadForm = ({ chatSpecific }) => {
  const dispatch = useDispatch();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [buttonText, setButtonText] = useState("Upload Documents");
  const [isLoading, setIsLoading] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const fileProgress = useSelector((state) => state.file?.fileProgress);
  const [localFiles, setLocalFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setLocalFiles(fileProgress || []);
  }, [fileProgress]);

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles) {
      setIsLoading(true);
      const filesToUpload = [...acceptedFiles]; // Create a copy of the accepted files

      setTimeout(() => {
        setUploadedFiles(filesToUpload);
        setButtonText("Upload Now");
        setIsFileSelected(true);
        setIsLoading(false);
      }, 1500); // Adjust the delay as needed
    }
  };

  const openFileSelector = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (isFileSelected && uploadedFiles.length > 0) {
      setIsLoading(true);
      const uploadPromises = uploadedFiles.map((file, index) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            dispatch(uploadFile(file, chatSpecific))
              .then(() => {
                setCurrentFileIndex(index + 1);
                resolve();
              })
              .catch((error) => {
                console.error("Upload error:", error);
                reject(error);
              });
          }, index * 5000); // Adjust the delay between each file upload as needed
        });
      });

      Promise.all(uploadPromises)
        .then(() => {
          setUploadedFiles([]);
          setButtonText("Upload Documents");
          setIsFileSelected(false);
          setIsLoading(false);
          setCurrentFileIndex(0);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setIsLoading(false);
        });
    }
  }, [isFileSelected, uploadedFiles, dispatch]);

  const handleUploadClick = () => {
    // const fileInput = document.getElementById("fileInput");
    // fileInput.click();
    if (uploadedFiles) {
      setIsLoading(true);
      dispatch(uploadFile(uploadedFiles, chatSpecific))
        .then(() => {
          setUploadedFiles(null);
          setButtonText("Upload Documents");
          setIsFileSelected(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setIsLoading(false);
        });
    }
  };

  const renderDemoView = () => {
    if (uploadedFiles.length === 0) {
      // Placeholder for no files selected
      return (
        <div
          className={`upload-placeholder ${isFileSelected ? "selected" : ""}`}
        >
          <HiOutlineDocumentText className="icons" />
          <p>Drag & Drop or click to upload multiple files</p>
        </div>
      );
    }
    // const firstFile = uploadedFiles[0];
    // return renderFilePreview(firstFile, 0);
  };

  const renderFilePreview = (file, index) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();

    switch (fileExtension) {
      // Add cases for all supported file types and their previews
      case "jpg":
      case "jpeg":
      case "png":
      case "svg":
        return (
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="uploaded_img"
            key={index} // Ensure unique key for each image
          />
        );
      case "pdf":
        return (
          <embed
            src={URL.createObjectURL(file)}
            width="100%"
            height="120"
            type="application/pdf"
            key={index} // Ensure unique key for each PDF
          />
        );
      default:
        return (
          <div className="default-file-preview">
            <HiOutlineDocumentText className="icons" />
            <p className="mt-1">{file.name}</p>
          </div>
        );
    }
  };

  return (
    <div className="file_upload_form_area">
      <h3 className="add_D">Add documents</h3>
      <div className="file_upload_form">
        <div className="upload_form">
          <Dropzone
            onDrop={handleDrop}
            accept={{
              // image:['.jpg', '.jpeg', '.png', '.svg'],
              "application/pdf": [".pdf"],
              "text/plain": [".txt"],
              "application/vnd.ms-excel": [".xls"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
              "application/msword": [".doc"],
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                className={`dropzone ${isFileSelected ? "selected" : ""}`}
                onClick={openFileSelector}
              >
                <input {...getInputProps()} id="fileInput" ref={fileInputRef} />
                {renderDemoView()}
              </div>
            )}
          </Dropzone>
          <div className="upload-button-container">
            <button
              onClick={openFileSelector}
              className="btn mt-3"
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : buttonText}
            </button>
          </div>
          <div className="upload-progress">
            {uploadedFiles.length > 0 && (
              <div className="file-progress">
                {`Uploaded ${currentFileIndex} of ${uploadedFiles.length}`}
              </div>
            )}
            {localFiles.map(function (d) {
              return (
                <div className="file-progress" key={d.file.name}>
                  {d.file.name.length > 15
                    ? d.file.name.substring(0, 15) +
                      "..." +
                      d.file.name.substring(d.file.name.length - 3)
                    : d.file.name}
                  &nbsp;|&nbsp;
                  {d.error || Math.floor(d.progressEvent.progress * 100)}%
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { uploadFile })(FileUploadForm);
