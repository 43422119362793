import DocumentsTable from "../Components/Table/DocumentsTable";
import FileUploadForm from "../Components/ImageUpload/FileUploadForm";

export default function KnowledgeBase() {
  return <div className="settings-container">
    <div className="upload_area">
      <FileUploadForm />
      <DocumentsTable />
    </div>
  </div>
}