import { Button, Input, Modal } from 'antd'; // Import Ant Design components
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineSend, AiOutlineUnorderedList } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { createChat, getChats, updateCurrentChat } from "../../redux/actions/chatActions";
import { addMessage, createMessage, getMessages, resetChat } from "../../redux/actions/chatMessageActions";
import { getSettings } from "../../redux/actions/settingsActions";
import { getTemplates } from "../../redux/actions/templateActions";
import Avatar from "../assets/Images/Avatar/user.png";
import Avatar2 from "../assets/Images/Avatar/user_2.png";
import AIMessageLoader from "./AIMessageLoader";
import ChatLoader from "./ChatLoader";
import GridViewMessages from "./GridViewMessages";
import ListViewMessage from "./ListViewMessage";
import { GrPowerReset } from 'react-icons/gr';
import { getFiles } from '../../redux/actions/fileActions';

const ChatInterface = () => {
  const dispatch = useDispatch();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const templates = useSelector(state => state.template.templates)
  const { evidenceFormattingEnabled } = useSelector((state) => state.settings);
  const [editMessageIndex, setEditMessageIndex] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const currentChat = useSelector(state => state.chat.currentChat);
  const messages = useSelector(state => state.message.messages);
  const loading = useSelector(state => state.message.fetching);
  const username = useSelector(state => state.user?.user?.name);
  const isAdmin = useSelector(state => state.user?.userData?.is_admin);
  const useSearchAPI = useSelector(state => state.settings?.useSearchAPI);

  const allFiles = useSelector(state => state.file?.files);
  useEffect(() => {
    dispatch(getFiles());
}, [dispatch]);

  const chats = useSelector(state => state.chat.chats);
  useEffect(()=>{
    if(!currentChat) {
      if(chats.length !== 0) {
        dispatch(updateCurrentChat(chats[0]));
      }
    }
  }, [chats, currentChat])

  const userAvatar = Avatar;
  const scrollRef = useRef(null);
  
  const [answerformat, setAnswerformat] = useState('list');
  const [isFetching, setIsFetching] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState('48px');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [displayTextarea, setDisplayTextarea] = useState(true);
  const [abortController, setAbortController] = useState(null);
  const [isScroll, setScrollType] = useState(false)

  const chatHistoryRef = useRef();

  const createAbortController = () => {
    const controller = new AbortController();
    setAbortController(controller);
    return controller;
  };


  const toggleFormat = () => {
    setAnswerformat(answerformat === 'list' ? 'grid' : 'list');
  };

  const handleTextareaChange = (e) => {
    setInputValue(e.target.value);
    setTextareaHeight('48px');
    const scrollHeight = e.target.scrollHeight;
    if (scrollHeight > e.target.clientHeight) {
      setTextareaHeight(scrollHeight + 'px');
    }
  };

  const stopGenerating = useCallback(() => {
    setIsFetching(false);
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setInputValue("");
      setTextareaHeight('48px');
      setRequestInProgress(false);
    }
  }, [abortController]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    const cleanup = () => {
      stopGenerating();
    };
    window.addEventListener('beforeunload', cleanup);
    return () => {
      window.removeEventListener('beforeunload', cleanup);
    };
  }, [stopGenerating, chats]);

  useEffect(() => {
    if (isScroll && messages.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      setScrollType(false);
    }
  }, [isScroll, messages])

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   if (inputRef.current && !inputRef.current.contains(event.target)) {
    //     stopGenerating();
    //   }
    // };
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        stopGenerating();
      }
    };
    // window.addEventListener('click', handleClickOutside);
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      // window.removeEventListener('click', handleClickOutside);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [stopGenerating]);

  useEffect(() => {
    if (chats.length > 0) {
      if(!chats[0].is_hidden)
      dispatch(updateCurrentChat(chats[0]));
      dispatch(getMessages(chats[0]?.id));
      setScrollType(true);
    } else {
      dispatch(updateCurrentChat(null));
    }
  }, [chats, dispatch]);

  useEffect(() => {
    dispatch(getChats());
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (messages.length === 0) {
      setDisplayTextarea(true);
    } else {
      setDisplayTextarea(false);
    }
  }, [currentChat?.id, messages, setDisplayTextarea]);

  const [isVendorModalVisible, setIsVendorModalVisible] = useState(false);
  const [vendorName, setVendorName] = useState('');

  const sendMessage = async () => {
    if (inputValue.trim() === "") return;

    if (requestInProgress) return;
    setDisplayTextarea(false);
    const questions = inputValue.split('\n');

    if (!currentChat) {
      toast.info('Select a chat', {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 2000
      });
      return;
    }
    setRequestInProgress(true);
    setInputValue("");
    setTextareaHeight('48px');

    const batchSize = 1;
    const groupedQuestions = [];

    // Split questions into batches of 5
    for (let i = 0; i < questions.length; i += batchSize) {
      const batch = questions.slice(i, i + batchSize);
      groupedQuestions.push(batch);
    }
    // set controller signal
    const controller = createAbortController();

    // Process each batch
    for (let batchIndex = 0; batchIndex < groupedQuestions.length; batchIndex++) {

      const batch = groupedQuestions[batchIndex];
      const ques_data = batch
        .filter(ques => ques.trim() !== "")
        .map(ques => ({
          data_ids: allFiles.map(file => file.id),
          current_id: currentChat?.id,
          query: ques.trim(),
          context: "true",
          vendor_name: vendorName
        }));
      if (ques_data.length === 0) {
        continue
      }
      const payload = {
        "queries_data": ques_data
      };

      const userMessage = {
        message_text: ques_data[0].query,
        message_type: "question",
      };

      dispatch(addMessage(userMessage));
      setIsFetching(true);
      setRequestInProgress(true);

      try {
        await dispatch(createMessage(payload, controller.signal, setIsFetching, setRequestInProgress, evidenceFormattingEnabled));
      } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
          console.error('Request was canceled.');
          setIsFetching(false);
        }
      }
    }

    setIsFetching(false);
    setTextareaHeight('48px');
    setRequestInProgress(false);
    setVendorName(''); // Clear vendor name after sending
  };

  const handleSendClick = () => {
    if(useSearchAPI) {
      setIsVendorModalVisible(true);
    }
    else {
      sendMessage();
    }
  };

  const resetCurrentChat = () => {
    dispatch(resetChat(currentChat?.id));
    setInputValue("");
    setTextareaHeight('48px');
    setRequestInProgress(false);
    setVendorName('');
  }

  const handleModalSubmit = () => {
    if (vendorName.trim() === "") {
      toast.error("Please enter a vendor name", {
        position: toast.POSITION.BOTTOM_RIGHT,
        hideProgressBar: true,
        autoClose: 2000
      });
      return;
    }
    setIsVendorModalVisible(false);
    sendMessage();
  };

  const handleModalCancel = () => {
    setIsVendorModalVisible(false);
    setVendorName('');
  };

  const editMessage = (messageIndex) => {
    setEditMessageIndex(messageIndex);
  };

  return (
    <div className="chat-interface">
      <div className="chat-windows">
        <div className="chat-window">
          {/* <div className={`tabs-toggle ${showTabs ? "active" : ""}`}>
            <button onClick={handleClick} className="btn">
              <FiMenu />
            </button>padding: '17px',
          </div> */}
          <div className="chat-history" ref={chatHistoryRef}>
            {currentChat && (
              <div className={`chat_title`}>
                <h1>Hello world</h1>
                <span>
                  <img src={Avatar2} alt="" />
                  <h3 className="title">
                    {chats.find((chat) => chat?.id === currentChat?.id)?.title}
                  </h3>
                </span>
                <BiPencil className="icons" />
              </div>
            )}
            {!(Array.isArray(messages) && messages.length > 0) && loading ? (
              <ChatLoader />
            ) : currentChat ? (
              Array.isArray(messages) && messages.length > 0 ? answerformat === 'list' ? (
                messages.map((message, index) => (
                  <div key={index} ref={scrollRef}>
                    {isFetching && index === messages.length - 1 && message?.message_type === 'question' ? (
                      <>
                        <ListViewMessage
                          key={index}
                          index={index}
                          content={message?.message_text}
                          isUser={message?.message_type === 'question' ? true : false}
                          avatar={message?.message_type === 'question' ? userAvatar : null}
                          userName={message?.message_type === 'question' ? username : 'AI'}
                          isEditable={editMessageIndex === index}
                          onEdit={() => editMessage(index)}
                        />
                        <AIMessageLoader />
                      </>
                    ) : (
                      <ListViewMessage
                        key={index}
                        index={index}
                        content={message?.message_text}
                        isUser={message?.message_type === 'question' ? true : false}
                        avatar={message?.message_type === 'question' ? userAvatar : null}
                        userName={message?.message_type === 'question' ? username : 'AI'}
                        isEditable={editMessageIndex === index}
                        evidence={{ page_content: (message?.formatted_context?.length ? message?.formatted_context : message?.context_text), metadata: message?.message_metadata }}
                        onEdit={() => editMessage(index)}
                      />
                    )}
                  </div>
                ))
              ) : (
                <GridViewMessages messages={messages} sref={scrollRef} isFetching={loading} />
              ) : (
                <div
                  style={{
                    display: 'flex', justifyContent: 'center',
                    alignItems: "center", height: '100%'
                  }}
                  onClick={() => setDisplayTextarea(true)}
                >
                  {!displayTextarea ? (
                    <h4 style={{ fontSize: '24px' }}>Start a conversation</h4>
                  ) : (
                    <textarea
                      type="text"
                      placeholder="Type your message..."
                      value={inputValue}
                      onChange={handleTextareaChange}
                      style={{ height: '96%', marginBottom: '15px' }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSendClick();
                        }
                      }}
                    ></textarea>
                  )}
                </div>
              )
            ) : (
              <div
                style={{
                  display: 'flex', justifyContent: 'center',
                  alignItems: "center", height: '100%'
                }}
              >
                <h4 style={{ fontSize: '24px' }}>Please select a chat</h4>
              </div>
            )}
          </div>
        </div>
        <div className="input-container">
          {!displayTextarea && (
            <textarea
              type="text"
              placeholder="Type your message..."
              value={inputValue}
              rows={inputValue.split('\n').length + 1}
              onChange={handleTextareaChange}
              style={{ height: textareaHeight }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSendClick();
                }
              }}
              disabled={requestInProgress}>
            </textarea>
          )}
          {requestInProgress ? (
          <div
            style={{
              position: "absolute",
              fontSize: "16px",
              right: "0.5vw",
              padding: "5px 10px 5px 10px",
              borderRadius: "4px",
              backgroundColor: "rgb(96 100 142)",
              cursor: "pointer",
            }}
            onClick={stopGenerating}
          >
            Stop Generating
          </div>
        ) : (
          <>
            <button
              onClick={handleSendClick}
              disabled={requestInProgress}
              className="send_btn"
              title="Send"
              style={{
                position: "absolute",
                right: "2.5vw"
              }}
            >
              <AiOutlineSend />
            </button>
            <button
              onClick={resetCurrentChat}
              disabled={requestInProgress}
              className="send_btn"
              style={{ 
                position: "absolute",
                right: "0.7vw",
              }}
              title="Reset Chat"
            >
              <GrPowerReset style={{ filter: 'brightness(0) invert(1)' }} />
            </button>
          </>
        )}
        </div>
      </div>
      <Modal
        title="Enter Vendor Name"
        open={isVendorModalVisible}
        onCancel={handleModalCancel}
      >
        <Input
          placeholder="Vendor Name"
          value={vendorName}
          onChange={(e) => setVendorName(e.target.value)}
          onPressEnter={handleModalSubmit}
        />
        <div style={{ marginTop: '1rem', textAlign: 'right' }}>
          <Button type="primary" onClick={handleModalSubmit}>
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ChatInterface;