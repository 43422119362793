import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "../Table/UsersTable";
import { getTeams } from "../../redux/actions/adminTeamsAction";


export function SuperAdminSettings() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers);
  const isAdmin = useSelector((state) => state.user.userData?.is_admin);

  const [activeTab, setActiveTab] = React.useState("users");

  useEffect(() => {
    if (isAdmin === true) {
      dispatch(getTeams());
    }
  }, [dispatch, isAdmin]);

  return (
    <div className="user_page" style={{ marginBottom: "20px" }}>
      <div
        className="section_title section_title_row"
        style={{ paddingBottom: "1%" }}
      >
        <div
          className={
            activeTab === "users" ? "user-menu-btn-active" : "user-menu-btn"
          }
          onClick={() => setActiveTab("users")}
        >
          <h2>Users({customers?.length ? customers.length : 0})</h2>
        </div>
      </div>
      {activeTab === "users" && <UsersTable />}
    </div>
  );
}