import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogInputField from "../Components/InputField/LogInputField";
import PasswordInput from "../Components/InputField/PasswordInput";
import Login_img from "../Components/assets/Images/Login.png";
import { changePassword } from "../redux/actions/authActions";
import { setUser } from "../redux/actions/userActions";

function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [isLengthValid, setIsLengthValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
    const [isChallenge, setIsChallenge] = useState(false);
    const [user, setUserState] = useState(null);
    const [challengeResponse, setChallengeResponse] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "email") {
            setEmail(value);
        } else if (name === "otp") {
            setOtp(value);
        } else if (name === "password") {
            setPassword(value);
            // Validate password conditions
            const isLengthValid = value.length >= 8;
            const isUppercaseValid = /[A-Z]/.test(value);
            const isLowercaseValid = /[a-z]/.test(value);
            const isNumberValid = /[0-9]/.test(value);
            const isSpecialCharValid = /[^A-Za-z0-9]/.test(value);
            setIsLengthValid(isLengthValid);
            setIsUppercaseValid(isUppercaseValid);
            setIsLowercaseValid(isLowercaseValid);
            setIsNumberValid(isNumberValid);
            setIsSpecialCharValid(isSpecialCharValid);
            setIsPasswordValid(
                isLengthValid &&
                    isUppercaseValid &&
                    isLowercaseValid &&
                    isNumberValid &&
                    isSpecialCharValid
            );
        } else if (name === "confirmPassword") {
            setConfirmPassword(value);
            setIsConfirmPasswordValid(value === password);
        }
    };

    const handleSuccessfulPasswordChange = async () => {
        try {
            const user = await Auth.signIn(email, password);
            // Check if the custom challenge is required
            if (user.challengeName === "CUSTOM_CHALLENGE") {
                setIsChallenge(true);
                setUserState(user);
            } else if (user?.signInUserSession) {
                dispatch(setUser(user.signInUserSession.getIdToken().payload));
                Cookies.set("isAuthenticated", "true");
                navigate("/home");
            }
        } catch (error) {
            toast.info(`${error?.message}`);
        }
    };

    const handleChallengeSubmit = async (e) => {
        e.preventDefault();

        try {
            const loggedInUser = await Auth.sendCustomChallengeAnswer(
                user,
                challengeResponse
            );
            if (loggedInUser?.signInUserSession) {
                dispatch(
                    setUser(loggedInUser.signInUserSession.getIdToken().payload)
                );
                Cookies.set("isAuthenticated", "true");
                navigate("/home");
            } else {
                toast.warning("Invalid code. Please try again.");
            }
        } catch (error) {
            toast.info(`${error?.message}`);
        }
    };

    const handleChallengeChange = (event) => {
        setChallengeResponse(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(changePassword(email, password, otp))
            .then(() => {
                toast.success(`New Password updated successfully`, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
                handleSuccessfulPasswordChange();
            })
            .catch((error) => {
                console.error("Forgot password error", error);
                toast.info(`${error}`, {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true,
                    autoClose: 2000,
                });
            });
    };

    return (
        <div className="logIn_page">
            <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="login_form">
                        <div className="login_header">
                            <h2>Questionade</h2>
                            <div className="lang_selector">
                                <p>
                                    Don’t have an account?{" "}
                                    <Link to="/">Sign In!</Link>
                                </p>
                            </div>
                        </div>
                        <div className="form_area">
                            <div className="mb-5">
                                <h1>Forgot Your Password</h1>
                                <p className="mt-2">
                                    Enter your registered email to reset your
                                    password
                                </p>
                            </div>
                            {!isChallenge ? (
                                <form action="" className="input_form">
                                    <LogInputField
                                        name="email"
                                        type="email"
                                        value={email}
                                        placeholder="Email"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <PasswordInput
                                        name="password"
                                        type="password"
                                        value={password}
                                        placeholder="New Password"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <PasswordInput
                                        name="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        placeholder="Confirm Password"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <PasswordInput
                                        name="otp"
                                        type="password"
                                        value={otp}
                                        placeholder="OTP"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    {password && (
                                        <ul className="password-conditions">
                                            <li
                                                className={
                                                    isLengthValid
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                At least 8 characters long
                                            </li>
                                            <li
                                                className={
                                                    isUppercaseValid
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                Contains at least one uppercase
                                                letter
                                            </li>
                                            <li
                                                className={
                                                    isLowercaseValid
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                Contains at least one lowercase
                                                letter
                                            </li>
                                            <li
                                                className={
                                                    isNumberValid
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                Contains at least one number
                                            </li>
                                            <li
                                                className={
                                                    isSpecialCharValid
                                                        ? "text-green"
                                                        : "text-red"
                                                }
                                            >
                                                Contains at least one special
                                                character
                                            </li>
                                        </ul>
                                    )}
                                    {confirmPassword &&
                                        !isConfirmPasswordValid && (
                                            <ul className="password-conditions">
                                                <li className="text-red">
                                                    Passwords do not match
                                                </li>
                                            </ul>
                                        )}
                                    {/* <Link to="/dashboard"> */}
                                    <button
                                        className="btn"
                                        onClick={(e) => handleSubmit(e)}
                                        disabled={
                                            !isPasswordValid ||
                                            !isConfirmPasswordValid ||
                                            !otp
                                        }
                                    >
                                        Submit
                                    </button>
                                    {/* </Link> */}
                                </form>
                            ) : (
                                <form
                                    className="input_form"
                                    onSubmit={handleChallengeSubmit}
                                >
                                    <h3>Enter the code sent to your email</h3>
                                    <LogInputField
                                        type="text"
                                        name="challengeResponse"
                                        value={challengeResponse}
                                        placeholder="Enter your code here."
                                        onChange={handleChallengeChange}
                                    />
                                    <button className="btn" type="submit">
                                        Submit Code
                                    </button>
                                </form>
                            )}
                        </div>
                        <div className="logIn_footer">
                            <Link to="">Privacy Policy</Link>
                            <p>Copyright 2024</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="login_img">
                        <img src={Login_img} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
