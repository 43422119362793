import { ConfigProvider, Table } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { BsTrash, BsDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFile, getFiles, downloadFile } from "../../redux/actions/fileActions";

const DocumentsTable = () => {
  const { Column } = Table;
  const userId = useSelector(state => state.user.userData?.id);
  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);

  const allFiles = useSelector(state => state.file?.files);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setFiles(allFiles.filter(file => !file.chat_id))
  }, [allFiles]);

  const handleDelete = (id, fileName) => {
    dispatch(deleteFile(id, fileName, userId));
  }

  const handleDownload = (id) => {
    dispatch(downloadFile(id));
  }

  useEffect(() => {
    dispatch(getFiles());
  }, [dispatch]);
  
  useEffect(() => {
    if (files && Array.isArray(files)) {
      setData(
        files.map((file, index) => ({
          key: `${file?.id}`,
          name: file?.source_filename,
          type: file?.source_file_extensions,
          date: file?.created_at,
          fSize: file?.file_size,
        }))
      );
    } else {
      setData([]);
    }
  }, [files]);
  

  return (
    <ConfigProvider theme={{
      components: {
        Table: {
          rowSelectedBg: '#224560'
        }
      }
    }}>
      <div className="table-wrapper">
        <Table
          theme="dark"
            dataSource={data}
            pagination={false}
            className="custom-table mb-4"
            bordered={false}
          >
            <Column title="File Name" dataIndex="name" className="custom_table_header" width={250} key="name" />
            <Column title="Type" dataIndex="type" className="custom_table_type" width={80} key="type" />
            <Column title="Date" dataIndex="date" width={120} key="date" />
            <Column title="File Size" dataIndex="fSize" className="custom_table_fSize" width={100} key="fSize" />
            <Column
              title="Action" 
              className="custom_table_action" 
              width={120} 
              key="Action" 
              render={(_, record) => (
                <div className="d-flex gap-3">
                  <Link to="">
                    <BsDownload onClick={() => handleDownload(record?.key)} />
                  </Link>
                  <Link to="">
                    <BsTrash onClick={() => handleDelete(record?.key, record?.name)} />
                  </Link>
                </div>
              )}
            />
        </Table>
      </div>
    </ConfigProvider>
  );
};

export default DocumentsTable;
