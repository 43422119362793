import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DownloadQuestionnaire,
    getQuestionnaires,
    DeleteQuestionnaireRun,
    ShowQuestionnaire,
    StartQuestionnaireRun
} from "../redux/actions/questionnaireActions";
import QuestionnaireForm from "../Components/DialogBox/QuestionnaireForm";
import {Table, Form, Button, Tooltip, Tag, Modal} from "antd";
import {MdDeleteOutline, MdMenu, MdListAlt } from "react-icons/md";
import {SlArrowLeft, SlArrowRight} from "react-icons/sl";
import DocumentsTable from "../Components/Table/DocumentsTable";
import FileUploadForm from "../Components/ImageUpload/FileUploadForm";
import {toast} from "react-toastify";
import _ from 'lodash';
import { formatDistanceToNow, format } from 'date-fns';
import {BiDownload} from "react-icons/bi";
import QuestionnaireRunModal from "../Components/DialogBox/QuestionnaireRunModal";
import { getFiles } from "../redux/actions/fileActions";

let statusInterval

function Questionnaire() {
    const dispatch = useDispatch();
    const [modifiedQuestionnaires, setModifiedQuestionnaires] = useState([]);
    const [selectedFileIds, setSelectedFileIds] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(undefined);

    const questionnaires = useSelector(state => state.questionnaire.questionnaires);

    const documentsCount = useSelector(state => state.file.files?.length || 0);
    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

    const showForm = useSelector(state => state.questionnaire.showForm);
    const setShowForm = (val) => {
        dispatch({ type: 'SHOW_QUESTIONNAIRE_FORM', payload: val })
    }

    const createClick = () => {
        if (documentsCount === 0) {
            toast.warning("Please upload documents to the Knowledge Base before adding a questionnaire.");
            return;
        }

        dispatch({ type: 'CLEAR_QUESTIONNAIRE_FORM' })
        setShowForm(true);
    }

    useEffect(() => {
        dispatch(getQuestionnaires());
    }, []);

    useEffect(() => {
        if (statusInterval) {
          clearInterval(statusInterval)
        }
        const questionnaireRuns = []
        const questionnairesTemp = questionnaires.map((questionnaire) => {
           questionnaire.sheets_count = questionnaire.sheets.length;
           let count = 0;
           questionnaire.sheets.forEach(sheet => {
              count += sheet.questions.length;
           });
           questionnaire.questions_count = count;
           return questionnaire;
        });
        questionnairesTemp.forEach(questionnaire => {
          questionnaire.runs.forEach(run => questionnaireRuns.push({questionnaire, run}))
        })
        const runningQuestionnaire = questionnaireRuns.some(({ run }) => run?.status !== 'Complete')
        if (runningQuestionnaire) {
          statusInterval = setInterval(() => dispatch(getQuestionnaires()), 10000);
        }
        setModifiedQuestionnaires(_.orderBy(questionnaireRuns, ['run.created_at'], ['desc']));
    }, [questionnaires]);

    const showQuestionnaire = (questionnaire) => {
        dispatch(ShowQuestionnaire(questionnaire));
        setShowForm(true);
    };

    const removeQuestionnaireRun = (questionnaire, run) => {
        dispatch(DeleteQuestionnaireRun(questionnaire, run));
    };

    const columns = [
        {
            title: 'Customer',
            dataIndex: ['questionnaire', 'name'],
            key: 'questionnaire.name',
        },
        {
            title: 'Questions',
            dataIndex: ['questionnaire', 'questions_count'],
            key: 'questionnaire.questions_count',
            align: 'center',
        },
        {
            title: 'Files Used',
            dataIndex: 'files_used',
            key: 'files_used',
            align: 'center',
            render: (_, record) => (
                <Button
                    size="medium"
                    onClick={() => {
                        setIsModalOpen('filesUsed');
                        setSelectedQuestionnaire(record);
                    }}
                    variant="filled"
                    style={{background: '#2b375c', color: '#e5e5eb', border: 'none', borderRadius: '10px', fontWeight: 600}}
                >
                    View
                </Button>
            )
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (_, record) => format(record.run.created_at, 'MM/dd/yyyy')
        },
        {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
            align: 'center',
            render: (_, record) => format(record.run.created_at, 'HH:mm')
        },
        {
            title: 'Actions',
            align: 'center',
            render: (_, record, index) => {
                // Determine if the current record is the most recent for its questionnaire ID
                const isMostRecent = index === modifiedQuestionnaires.findIndex(
                    (entry) => entry.questionnaire.id === record.questionnaire.id
                );
    
                return (
                    <div key={`actions-${index}`} style={{display: 'flex', gap: '20px', justifyContent: 'center'}}>
                        <Tooltip title="Download Completed Excel Sheet">
                            <BiDownload
                                className="icons mouseCursor"
                                onClick={() => { downloadFile(record.questionnaire, record.run.id); }}
                            />
                        </Tooltip>
                        <Tooltip title="Remove Questionnaire">
                            <a onClick={() => { removeQuestionnaireRun(record.questionnaire, record.run); }}>
                                <MdDeleteOutline style={{ fontSize: '25px' }} />
                            </a>
                        </Tooltip>
                        { (!record.run || record.run.status === 'Complete') ? (
                            (isMostRecent && <Button
                                size="medium"
                                onClick={() => runQuestionnaire(record.questionnaire)}
                                style={{background: '#3b4267', color: '#eaeaef', borderColor: '#d0d3d7', borderRadius: '10px', fontWeight: 600}}
                            >
                                Rerun
                            </Button>)
                        ) : (
                            <Tag className="text-center" color="green">{questionCount(record)}</Tag>
                        )}
                    </div>
                );
            }
        },
    ];

    const listFiles = (names) => {
        return (
            <ul className="fileList">
                {names.map((name) => {
                    return (<li>{name}</li>)
                })}
            </ul>
        )
    }

    const downloadFile = async (questionnaire, run_id) => {
        toast.info("preparing download", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
        dispatch(DownloadQuestionnaire(questionnaire, run_id));
    };

    const questionCount = (questionnaire) => {
        if (!questionnaire.run) {
            return '';
        }
        let totalQuestions = 0;
        let answeredQuestions = 0;
        let lastRun = questionnaire.run;

        totalQuestions += lastRun.question_responses.length;
        answeredQuestions += lastRun.question_responses.filter((qr) => qr.status === 'Answered').length;
        return `${answeredQuestions} of ${totalQuestions}`;
    }

    const runQuestionnaire = async (questionnaire) => {
        setSelectedQuestionnaire(questionnaire);
        setIsModalOpen('questionnaire');
    };

    const handleOk = async (values) => {
        setIsModalOpen(false);
        toast.info("Please wait a moment for the questionnaire to start processing.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
        await dispatch(StartQuestionnaireRun(selectedQuestionnaire, selectedFileIds, new Date()))
        dispatch(getQuestionnaires());
        toast.info("Questionnaire is being processed, this may take a few minutes.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
    };

    const handleCancel = () => setIsModalOpen(false);
    
    return (
        <div className="dashboard_page">
            <Modal zIndex='1000' title='Start new run' open={isModalOpen === 'questionnaire'} onCancel={handleCancel}>
                <QuestionnaireRunModal
                    questionnaireId={selectedQuestionnaire?.id}
                    selectedFileIds={selectedFileIds}
                    setSelectedFileIds={setSelectedFileIds}
                    onSubmit={handleOk}
                />
            </Modal>
            {isModalOpen === 'filesUsed' && (
                <Modal
                    zIndex='1000'
                    title='Questionnaire Run'
                    open={true}
                    onCancel={handleCancel}
                    className='modalStyle'
                >
                    <Table
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: ['run', 'name'],
                                key: 'run.name',
                            },
                            {
                                title: 'Files Used',
                                render: (_, row) => (
                                  <Tooltip title={listFiles(row.run.usertraindata_names)}>
                                      <span className="viewFiles">View</span>
                                  </Tooltip>
                                )
                            },
                            {
                                title: 'Actions',
                                render: (_, row) => (
                                    <Tooltip title="Download Completed Excel Sheet">
                                        <BiDownload
                                            className="icons mouseCursor"
                                            onClick={() => { downloadFile(row.questionnaire, row.run.id) }}
                                        />
                                    </Tooltip>
                                )
                            },
                        ]}
                        dataSource={[selectedQuestionnaire]}
                        pagination={false}
                    />
                </Modal>
            )}
            <div className="row">
                <div>
                    <div className="d-flex flex-row w-100 align-items-center">
                        <div className="flex-grow-1">
                            <h3>Questionnaires</h3>
                        </div>
                        <div className="text-end">
                            <button className="btn" onClick={createClick}>Add Questionnaire</button>
                        </div>
                    </div>
                    <QuestionnaireForm isModalOpen={showForm} setIsModalOpen={setShowForm} />
                    <div className="mt-2">
                        <Table columns={columns} dataSource={modifiedQuestionnaires} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Questionnaire;
