import authAxios from "../../services/authAxios";
import { getUser } from "./authActions";

export const getMessages = (chatId, signal) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.get('chat-messages?chat_id=' + chatId, { signal });
        dispatch({ type: 'GET_CHAT_MESSAGE_SUCCESS', payload: response.data });
        dispatch(getUser());
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log("aborting.........")
        } else {
            dispatch({ type: 'GET_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const getMessagesForCS = (chatId, signal) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.get('rubric/chat-messages?chat_id=' + chatId, { signal });
        dispatch({ type: 'GET_CHAT_MESSAGE_SUCCESS', payload: response.data });
        dispatch(getUser());
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log("aborting.........")
        } else {
            dispatch({ type: 'GET_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const getMessagesForSectionScoring = (chatId, signal) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.get('section/chat-messages?chat_id=' + chatId, { signal });
        dispatch({ type: 'GET_CHAT_MESSAGE_SUCCESS', payload: response.data });
        dispatch(getUser());
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log("aborting.........")
        } else {
            dispatch({ type: 'GET_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const createMessage = (message, signal, setIsFetching, setRequestInProgress, evidenceFormattingEnabled) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.post('chat-queries', message, {
            signal,
        });
        dispatch({ type: 'CREATE_CHAT_MESSAGE_SUCCESS', payload: response.data });

        dispatch({type: 'STOP_FETCHING_MESSAGES'});
        setIsFetching(false);
        setRequestInProgress(false);

        // fetch the formatted context by parallel call
        if (response.data[0] && evidenceFormattingEnabled) {
            const messageId = response.data[0].id;
            dispatch({ type: 'START_FETCHING_CONTEXT', messageId });

            const context = response.data[0].context;
            const formattedContext = await authAxios.post('formatted-chat-context', {
                message_id: messageId,
                contexts: context
            });

            dispatch({ type: 'FETCHING_FORMATTED_CONTEXT', payload: formattedContext.data, messageId });
        }

    } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
            console.error("aborting.........")
            throw error
        } else {
            dispatch({ type: 'CREATE_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const createMessageForCS = (message, signal, setIsFetching, setRequestInProgress, evidenceFormattingEnabled, userMsgOrderNo) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.post('rubric/chat-queries', message, {
            signal,
        });
        const data = response.data.map((item) => {
            return {
                ...item,
                userMsgOrderNo
            }
        })
        dispatch({ type: 'UPDATE_MESSAGE_ORDER', payload: data[0].order_no + 1 });
        dispatch({ type: 'CREATE_CHAT_MESSAGE_SUCCESS_FOR_CS', payload: data });

        dispatch({type: 'STOP_FETCHING_MESSAGES'});
        setIsFetching(false);
        setRequestInProgress(false);

        // fetch the formatted context by parallel call
        if (response.data[0] && evidenceFormattingEnabled) {
            const messageId = response.data[0].id;
            dispatch({ type: 'START_FETCHING_CONTEXT', messageId });

            const context = response.data[0].context;
            const formattedContext = await authAxios.post('formatted-chat-context', {
                message_id: messageId,
                contexts: context
            });

            dispatch({ type: 'FETCHING_FORMATTED_CONTEXT', payload: formattedContext.data, messageId });
        }

    } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
            console.error("aborting.........")
            throw error
        } else {
            dispatch({ type: 'CREATE_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const createMessageForSectionScoring = (message, signal, setIsFetching, setRequestInProgress, evidenceFormattingEnabled, userMsgOrderNo) => async (dispatch) => {
    try {
        dispatch({ type: 'START_FETCHING_MESSAGES' })
        const response = await authAxios.post('section/chat-queries', message, {
            signal,
        });
        const data = response.data.map((item) => {
            return {
                ...item,
                userMsgOrderNo
            }
        })
        dispatch({ type: 'UPDATE_MESSAGE_ORDER', payload: data[0].order_no + 1 });
        dispatch({ type: 'CREATE_CHAT_MESSAGE_SUCCESS_FOR_SECTION_SCORING', payload: data });

        dispatch({type: 'STOP_FETCHING_MESSAGES'});
        setIsFetching(false);
        setRequestInProgress(false);

        // fetch the formatted context by parallel call
        if (response.data[0] && evidenceFormattingEnabled) {
            const messageId = response.data[0].id;
            dispatch({ type: 'START_FETCHING_CONTEXT', messageId });

            const context = response.data[0].context;
            const formattedContext = await authAxios.post('formatted-chat-context', {
                message_id: messageId,
                contexts: context
            });

            dispatch({ type: 'FETCHING_FORMATTED_CONTEXT', payload: formattedContext.data, messageId });
        }

    } catch (error) {
        if (error.name === 'AbortError' || error.name === 'CanceledError') {
            console.error("aborting.........")
            throw error
        } else {
            dispatch({ type: 'CREATE_CHAT_MESSAGE_ERROR', payload: error.message });
        }
    }
};

export const resetChat = (chat_id) => async (dispatch) => {
    try {
        const response = await authAxios.put(`chat/${chat_id}/reset`);
        return dispatch({ type: 'RESET_CHAT_SUCCESS'});
    } catch (error) {
        console.error(error);
    }
};

export const addMessage = (message) => ({
    type: 'ADD_MESSAGE',
    payload: message,
});

export const addMessageToCS = (message) => ({
    type: 'ADD_MESSAGE_TO_CS',
    payload: message,
});

export const addMessageToSectionScoring = (message) => ({
    type: 'ADD_MESSAGE_TO_SECTION_SCORING',
    payload: message,
});

export const updateMessageOrder = (messageOrderNo) => ({
    type: 'UPDATE_MESSAGE_ORDER',
    payload: messageOrderNo + 2,
});
