import { Button, Checkbox, List } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../redux/actions/fileActions";

export default function QuestionnaireRunModal({ questionnaireId, selectedFileIds, setSelectedFileIds, onSubmit }) {
    const dispatch = useDispatch();
    const allFiles = useSelector(state => state.file?.files);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

    useEffect(() => {
        setAllSelected(allFiles?.length > 0 && selectedFileIds.length === allFiles.length);
    }, [selectedFileIds, allFiles]);

    const handleToggleSelectAll = () => {
        if (allSelected) {
            setSelectedFileIds([]);
        } else {
            const allFileIds = allFiles.map(file => file.id);
            setSelectedFileIds(allFileIds);
        }
        setAllSelected(!allSelected);
    };

    useEffect(() => {
        const allFileIds = allFiles.map(file => file.id);
        setSelectedFileIds(allFileIds);
    }, [questionnaireId]);

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                <strong>Select Files</strong>
                <Button type="link" onClick={handleToggleSelectAll}>
                    {allSelected ? "Deselect All" : "Select All"}
                </Button>
            </div>
            <List
                size="small"
                bordered
                dataSource={allFiles}
                renderItem={(value) => (
                    <List.Item>
                        <Checkbox
                            style={{ marginRight: ".5rem" }}
                            checked={selectedFileIds.includes(value.id)}
                            onChange={() => {
                                if (selectedFileIds.includes(value.id)) {
                                    setSelectedFileIds(selectedFileIds.filter(v => v !== value.id));
                                } else {
                                    setSelectedFileIds([...selectedFileIds, value.id]);
                                }
                            }}
                        />
                        <div style={{ color: "black", width: "100%" }}>{value.source_filename}</div>
                    </List.Item>
                )}
            />
            <Button type="primary" style={{ marginLeft: "auto", marginTop: "1rem" }} onClick={onSubmit}>
                Run
            </Button>
        </>
    );
}
