const initialState = {
    messages: [],
    error: null,
    fetching: false,
    messageOrderNo: 1,
};

const chatMessageReducer = (state = initialState, action) => {
    switch (action.type) {
        case "START_FETCHING_MESSAGES":
            return {
                ...state,
                // messages: [...state.messages],
                error: null,
                fetching: true,
            };

        case "STOP_FETCHING_MESSAGES":
            return {
                ...state,
                fetching: false,
            };
        case "GET_CHAT_MESSAGE_SUCCESS":
            var tempMessageOrder = 1;
            action.payload.forEach((value) => {
                if (value?.order_no) {
                    tempMessageOrder = value?.order_no;
                }
            });
            return {
                ...state,
                messages: action.payload,
                error: null,
                fetching: false,
                messageOrderNo: tempMessageOrder,
            };
        case "GET_CHAT_MESSAGE_ERROR":
            return {
                ...state,
                messages: [],
                error: action.payload,
                fetching: false,
            };
        case "CREATE_CHAT_MESSAGE_SUCCESS":
            const transformedData = [];
            action.payload.forEach((value, index) => {
                transformedData.push({
                    message_text: value?.question,
                    message_type: "question",
                    context_text: null,
                    message_metadata: null,
                });
                transformedData.push({
                    id: value?.id,
                    message_text: value?.answer,
                    message_type: "answer",
                    context_text: value?.context,
                    formatted_context: [],
                    isContextFetching: false,
                    message_metadata: value?.metadata,
                });
            });
            return {
                ...state,
                messages: [...state.messages.slice(0, -1), ...transformedData],
                error: null,
            };

        case "CREATE_CHAT_MESSAGE_SUCCESS_FOR_CS":
            const CSTransformedData = [];
            action.payload.forEach((value, index) => {
                CSTransformedData.push({
                    message_text: value?.question,
                    rubric_text: value?.rubric,
                    message_type: "question",
                    context_text: null,
                    message_metadata: null,
                    order_no: value?.userMsgOrderNo,
                    base_score: value?.base_score,
                    predicted_score: value?.predicted_score,
                });
                CSTransformedData.push({
                    id: value?.id,
                    message_text: value?.answer,
                    message_type: "answer",
                    context_text: value?.context,
                    formatted_context: [],
                    isContextFetching: false,
                    message_metadata: value?.metadata,
                    order_no: value?.order_no,
                    base_score: value?.base_score,
                    predicted_score: value?.predicted_score,
                });
            });
            const findIndex = state.messages.findIndex(
                (message) =>
                    message?.message_type === "question" &&
                    message?.order_no === action.payload[0]?.userMsgOrderNo
            );

            return {
                ...state,
                messages: [
                    ...state.messages.slice(0, findIndex),
                    ...CSTransformedData,
                    ...state.messages.slice(findIndex + 2),
                ],
                error: null,
            };

        case "CREATE_CHAT_MESSAGE_SUCCESS_FOR_SECTION_SCORING":
            const sectionScoringTransformedData = [];
            action.payload.forEach((value, index) => {
                sectionScoringTransformedData.push({
                    message_text: value?.question,
                    rubric_text: value?.rubric,
                    message_type: "question",
                    context_text: null,
                    message_metadata: null,
                    order_no: value?.userMsgOrderNo,
                    base_score: value?.base_score,
                    predicted_score: value?.predicted_score,
                    name: value?.name,
                });
                sectionScoringTransformedData.push({
                    id: value?.id,
                    message_text: value?.answer,
                    message_type: "answer",
                    context_text: value?.context,
                    formatted_context: [],
                    isContextFetching: false,
                    message_metadata: value?.metadata,
                    order_no: value?.order_no,
                    base_score: value?.base_score,
                    predicted_score: value?.predicted_score,
                    name: value?.name,
                    explanation_text: value?.explanation_text,
                });
            });

            const findIndex_2 = state.messages.findIndex(
                (message) =>
                    message?.message_type === "question" &&
                    message?.order_no === action.payload[0]?.userMsgOrderNo
            );

            return {
                ...state,
                messages: [
                    ...state.messages.slice(0, findIndex_2),
                    ...sectionScoringTransformedData,
                    ...state.messages.slice(findIndex_2 + 2),
                ],
                error: null,
            };

        case "START_FETCHING_CONTEXT":
            const messages = [...state.messages];
            const mId = action.messageId;
            const mIndex = messages.findIndex((message) => message?.id === mId);

            messages[mIndex].isContextFetching = true;

            return {
                ...state,
                messages: messages,
                error: null,
            };
        case "FETCHING_FORMATTED_CONTEXT":
            const newMessages = [...state.messages];
            const messageId = action.messageId;
            const index = newMessages.findIndex(
                (message) => message?.id === messageId
            );
            newMessages[index].formatted_context =
                action.payload.formatted_context;
            newMessages[index].isContextFetching = false;

            return {
                ...state,
                messages: newMessages,
                error: null,
            };

        case "CREATE_CHAT_MESSAGE_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        
        case "RESET_CHAT_SUCCESS":
            return {
                ...state,
                messages: [],
                error: null,
            };
        
        case "ADD_MESSAGE":
            return {
                ...state,
                messages: [...state.messages, action.payload],
                error: null,
            };
        case "ADD_MESSAGE_TO_CS":
            return {
                ...state,
                messages: [
                    ...state.messages,
                    action.payload.user,
                    action.payload.ai,
                ],
                error: null,
            };

        case "ADD_MESSAGE_TO_SECTION_SCORING":
            return {
                ...state,
                messages: [
                    ...state.messages,
                    action.payload.user,
                    action.payload.ai,
                ],
                error: null,
            };

        case "UPDATE_MESSAGE_ORDER":
            return {
                ...state,
                messageOrderNo: action.payload,
                error: null,
            };
        default:
            return state;
    }
};

export default chatMessageReducer;
