import { Auth } from "aws-amplify";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Login_img from "../Components/assets/Images/Login.png";
import { getUser } from "../redux/actions/authActions";
import { setUser } from "../redux/actions/userActions";

function Verification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const username = useSelector((state) => {
    return state.user?.user?.user?.username;
  });
  const passwordFromState = location.state && location.state.password;

  const handleVerification = async (e) => {
    e.preventDefault();
    try {
      await Auth.confirmSignUp(username, code);
      const user = await Auth.signIn(username, passwordFromState);
      let idToken = user?.signInUserSession.getIdToken();
      dispatch(setUser(idToken?.payload));
      Cookies.set("isAuthenticated", "true");

      dispatch(getUser()).then(() => { navigate("/home"); });
    } catch (error) {
      console.error("Verification error", error);
      toast.info(`${error.message}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="logIn_page">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div
            className="otp-form"
            style={{
              height: "0% !important",
            }}
          >
            <div className="login_header">
              <Link to="/">
                <h2>Questionade</h2>
              </Link>
            </div>
            <div className="form_area">
              <div className="mb-5 mt-5">
                <h1>Please enter verification code</h1>
                <p className="mt-2 desc">
                  <b>Code is sent to your email</b>
                </p>
              </div>
              <form className="input_form" onSubmit={handleVerification}>
                <label>Verification Code:</label>
                <br />
                <input
                  type="text"
                  value={code}
                  style={{ width: "500px" }}
                  className="form-control"
                  onChange={(e) => setCode(e.target.value)}
                />
                <button className="btn" type="submit" style={{ width: "500px" }}>
                  Verify
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
