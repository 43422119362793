import { SuperAdminSettings } from "../Components/Settings/SuperAdminSettings";
import { UsersSettings } from "../Components/Settings/UsersSettings";
import { useSelector } from "react-redux";

const Settings = () => {
  const userData = useSelector((state) => state.user.userData);

  return <div className="settings-container">
    {
      userData?.is_admin && <SuperAdminSettings />
    }
    <UsersSettings />
  </div>
};

export default Settings;
