import {Link} from "react-router-dom";
import Login_img from "../../Components/assets/Images/Login.png";
import React from "react";

function VultrLogin() {
    const samlUrl = 'https://login.docubark.com/oauth2/authorize?identity_provider=SAML-4a54458a06f7&redirect_uri=https://app.docubark.com/home&response_type=CODE&client_id=c4j80e350hgrn145caf25icgc&scope=email%20openid%20profile';
    const redirectSaml = () => {
        window.location.href = samlUrl;
    };

    return (
        <div className="logIn_page">
            <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="login_form">
                        <div className="login_header">
                            <Link to="/">
                                <h2>Questionade</h2>
                            </Link>
                            <div className="lang_selector">
                                <p>
                                    Don&apos;t have an account? <Link to="/Signup">Sign up!</Link>
                                </p>
                            </div>
                        </div>
                        <div className="form_area">
                            <div className="mb-5">
                                <h1>Welcome Back</h1>
                                <p className="mt-2 desc">
                                    <b>Login into your account</b>
                                </p>
                            </div>
                            <div>
                                <button className="btn" onClick={redirectSaml}>
                                    Login with SAML
                                </button>
                            </div>
                        </div>
                        <div className="logIn_footer">
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <span>|</span>
                            <Link to="/tos">Terms of Service</Link>
                            <p>Copyright 2023</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="login_img">
                        <img src={Login_img} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VultrLogin;